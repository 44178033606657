import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationMenu } from '@modules/shared/schemas/navigation-menu.schema';
import { HeaderService } from '@modules/shared/services/header.service';
import { MediaQueryService } from '@modules/shared/services/media-query.service';
import { ScrollLockService } from '@modules/shared/services/scroll-lock.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MediaQueryChangeEvent } from '@workbench/core';
import { Observable } from 'rxjs';
import { languageConfig } from 'src/environments/language.config';

@Component({
  selector: 'mb-fly-in',
  templateUrl: './fly-in.component.html',
  styleUrls: ['./fly-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlyInComponent implements OnInit {
  public lvlOneNavigationMenus$ = new Observable<NavigationMenu[] | null>();
  public lvlTwoNavigationMenu: NavigationMenu | undefined;
  public lvlThreeNavigationMenu: NavigationMenu | undefined;
  public currentLanguage: string;
  public breadcrumbs: NavigationMenu[] = [];
  public isTabletView = false;
  public isDesktopView = false;
  public isNavigationLvlOneOpened = false;
  public isNavigationLvlTwoOpened = false;
  public isNavigationLvlThreeOpened = false;
  public lvlFourNavigationMenu: NavigationMenu | undefined;
  public isNavigationLvlFourOpened = false;

  public languages = languageConfig.languages.map((isoLang) => ({
    isoLang,
    label: isoLang.split('-')[0].toUpperCase(),
  }));

  @Output() breadcrumbsEvent = new EventEmitter<NavigationMenu[]>();

  @HostListener('window:wbresize', ['$event'])
  public onResize(event: CustomEvent<MediaQueryChangeEvent>) {
    this.isTabletView = ['mq3', 'mq4'].includes(event.detail.current);
    this.isDesktopView = ['mq5', 'mq6'].includes(event.detail.current);

    if (this.isDesktopView && this.isNavigationLvlOneOpened) {
      this.closeNavigationMenu();
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: any) {
    const path = event.composedPath() as Array<any>;

    const flyInContainer = path.find((p) =>
      ['wb-header-desktop-fly-in', 'wb-header-mobile-fly-in'].includes(p.tagName?.toLowerCase())
    );

    if (!flyInContainer) {
      this.closeNavigationMenu();
      return;
    }

    const anchor = path.find((p) => p.tagName?.toLowerCase() === 'a' && p.className === 'logo');
    if (anchor && !anchor.hasAttribute('routerlink')) {
      const href = anchor.getAttribute('href');
      this.router.navigateByUrl(href);
      this.closeNavigationMenu();
      event.preventDefault();
    }
  }

  constructor(
    private mediaQueryService: MediaQueryService,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private scrollLockService: ScrollLockService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.isTabletView = ['mq3', 'mq4'].includes(this.mediaQueryService.getCurrentMediaQuery());
    this.isDesktopView = ['mq5', 'mq6'].includes(this.mediaQueryService.getCurrentMediaQuery());
    this.currentLanguage = this.translateService.currentLang;

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });

    this.headerService.activeNavigationMenuLevel$.subscribe(({ level, menu }) => {
      this.openNavigationMenu(level, menu);
      this.cdr.detectChanges();
    });

    this.headerService.getCloseNavigationMenuEvent().subscribe(() => {
      this.scrollLockService.unlock();
      this.isNavigationLvlOneOpened = false;
      this.isNavigationLvlTwoOpened = false;
      this.isNavigationLvlThreeOpened = false;
      this.isNavigationLvlFourOpened = false;

      this.lvlTwoNavigationMenu = undefined;
      this.lvlThreeNavigationMenu = undefined;
      this.lvlFourNavigationMenu = undefined;

      // TODO: not sure if accessing the shadow root is ideal here. Revisit this once fly-in is cleaned up
      let scrolledFlyIns = document.querySelectorAll('.wb-header-desktop-fly-in[scrolled]');
      for (let i = 0; i < scrolledFlyIns.length; ++i) {
        scrolledFlyIns[i].shadowRoot!.querySelector('.body')!.scrollTop = 0;
      }

      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.lvlOneNavigationMenus$ = this.headerService.menu$;
  }

  public openNavigationMenu(level: number, item?: NavigationMenu) {
    this.scrollLockService.lock();
    switch (level) {
      case 1:
        this.isNavigationLvlOneOpened = true;
        this.lvlTwoNavigationMenu = undefined;
        this.lvlThreeNavigationMenu = undefined;
        this.lvlFourNavigationMenu = undefined;
        break;
      case 2:
        if (item?.children?.length) {
          this.lvlTwoNavigationMenu = item;
          this.isNavigationLvlTwoOpened = true;
          this.lvlThreeNavigationMenu = undefined;
          this.lvlFourNavigationMenu = undefined;
          this.isNavigationLvlThreeOpened = false;
        }
        break;
      case 3:
        if (item?.children?.length) {
          this.lvlThreeNavigationMenu = item;
          this.isNavigationLvlThreeOpened = true;
          this.lvlFourNavigationMenu = undefined;
          this.isNavigationLvlFourOpened = false;
        }
        break;
      case 4:
        if (item?.children?.length) {
          this.lvlFourNavigationMenu = item;
          this.isNavigationLvlFourOpened = true;
        }
    }
    if (item && (item.children === undefined || item.children.length == 0)) {
      this.navigateToPage(item);
      return;
    }
  }

  public onBack(backToLvl: number) {
    switch (backToLvl) {
      case 1:
        this.isNavigationLvlTwoOpened = false;
        this.lvlTwoNavigationMenu = undefined;
        this.isNavigationLvlOneOpened = true;
        break;
      case 2:
        this.isNavigationLvlThreeOpened = false;
        this.lvlThreeNavigationMenu = undefined;
        this.isNavigationLvlTwoOpened = true;
        break;
      case 3:
        this.isNavigationLvlFourOpened = false;
        this.lvlFourNavigationMenu = undefined;
        this.isNavigationLvlThreeOpened = true;
        break;
    }
  }

  public closeNavigationMenu() {
    this.headerService.closeMenu();
  }

  public navigateToPage(item: NavigationMenu) {
    this.router.navigate([item.path[this.currentLanguage]]);
    this.closeNavigationMenu();
  }

  // TBD: if search or global content hub gets re-instated
  public openContentHub(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.router.navigate(['search']);
    this.closeNavigationMenu();
  }

  public changeLanguage(language: string): void {
    this.translateService.use(language);
  }

  public isActive(item: NavigationMenu) {
    return item === this.lvlTwoNavigationMenu || item === this.lvlThreeNavigationMenu;
  }

  public openImprint(event: Event) {
    this.headerService.closeMenu();
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['legal/provider'], { state: { scrollRestoration: 'top' } });
  }

  public openPressContacts(event: Event) {
    this.headerService.closeMenu();
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['presscontacts']);
  }
}
